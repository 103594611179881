import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { animated } from "react-spring";
import { useFade } from "../transitions";

import ImageGrid from "../components/library/ImageGrid";
import { mq } from "../theme";

const dogsPageQuery = graphql`
  query DogsQuery {
    dogs: markdownRemark(fields: { slug: { eq: "/about/our-dogs/" } }) {
      fields {
        slug
      }
      frontmatter {
        dogs {
          name
          image {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
        pastPuppies {
          image {
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          caption
        }
      }
    }
  }
`;

const pageWrapperStyles = css`
  max-width: 70em;
  margin: auto;
`;

const sectionContainerStyles = css`
  padding: 16px;
`;

const dogDescriptionContainerStyles = css`
  ${mq("large")} {
    display: flex;
  }
`;

const dogDescriptionStyles = css`
  ${mq("large")} {
    margin: 16px;
    flex: 1;
  }
`;

const DogsPage = ({ transitionStatus }) => {
  const fade = useFade(transitionStatus);
  return (
    <StaticQuery
      query={dogsPageQuery}
      render={(data) => {
        const parents = data.dogs.frontmatter && data.dogs.frontmatter.dogs;
        const puppyImages = data.dogs.frontmatter.pastPuppies.map((puppy) => {
          const image = puppy.image.childImageSharp.fluid;
          return {
            image,
            caption: puppy.caption,
          };
        });

        return (
          <animated.div style={fade} css={pageWrapperStyles}>
            <div css={dogDescriptionContainerStyles}>
              {parents &&
                parents.map((parent, i) => (
                  <div
                    css={dogDescriptionStyles}
                    key={parent.name + parent.image}
                  >
                    <Img fluid={parent.image.childImageSharp.fluid} />
                    <div css={sectionContainerStyles}>
                      <h1>{parent.name}</h1>
                      <div>{parent.description}</div>
                    </div>
                  </div>
                ))}
            </div>
            <div css={[sectionContainerStyles]}>
              <h1>Our past puppies</h1>
              <ImageGrid images={puppyImages} />
            </div>
          </animated.div>
        );
      }}
    />
  );
};

export default DogsPage;
